import Headroom from '/lib/01_headroom.min.js'
import '/lib/cssua.min.js'
import '/lib/03_jquery.jscroll.min.js'
import '/lib/magnific-popup.min.js'
import Vivus from '/lib/07_vivus.min.js'
import '/lib/lazysizes.min.js'
import '/lib/modernizr.min.js'
import '/lib/slick.js'
import sal from '/lib/sal.js';
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

(function ($, window, document, undefined) {

  'use strict';

  /**
   * This is a description for EnergyEx object.
   *
   * @namespace EnergyEx
   * @author Mangrove
   */

  var EnergyEx = {

    /**
     * Initialize object
     * @function init
     * @memberof EnergyEx
     */

    init: function () {

      this._cache();
      this._events();
      this._vivus();
      this._searchProjects();
      this._initHeadroomHome();
      this._initPopups();
      this._subscriptionMessage();
      this._initInfiniteScroll();
      this._initCompaniesSlider();
      this._initNavSlider();
      this._initMobileSlider();
      this._initSal();
      this._initHeroSlider();
      this._initAccordion();
      this._initSmoothScroll();
      this._initModuleCarousel();
      this._initTooltips();

      if (this.$body.hasClass('home')) {
        this._setIntroHeight();
      }

      if (this.$body.hasClass('single-post')) {
        this._initMasonry();
      }

      if (this.$body.hasClass('page-template-page-multistep-form')) {
        this._initMultistepForm();
      }

      if (this.$body.hasClass('page-template-page-program-v2')) {
        this._initResourcesSlider();
      }

      if (this.$body.hasClass('page-template-page-hawaii')) {
        this._initInnovationSlider();
      }

      if (this.$body.hasClass('page-template-page-companies-rebrand')) {
        this._initCompaniesFilters();
      }

      if (this.$body.hasClass('page-template-page-portfolio-portal')) {
        this._initPortalSliders();
      }

      if (this.$body.hasClass('page-template-page-resources')) {
        this._initResourcesScripts();
      }

      if (this.$body.hasClass('page-template-page-cohort') || this.$body.hasClass('page-template-page-cohort-v2') ) {
        this._initCohortScripts();
      }

      if (this.$body.hasClass('page-template-page-portfolio-portal-events')) {
        this._initPortalEvents();
      }

      if ($('.js-video-slider').length) {
        this._initVideoSlider();
      }

      if ($('.js-feat-slider').length) {
        this._initPersonFeaturedSlider();
      }

      if ($('.alt-content-rows__nav').length) {
        this._addSmoothScroll();
      }

      if ($('.js-nav-slider').length) {
        this._initNavSlider();
      }

      if ($('.page-rebrand--hero').length > 0) {
        let thisWindowInnerHeight = (window.innerHeight - 66);
        //$('.page-rebrand--hero').height(thisWindowInnerHeight);
        $('.page-rebrand--hero').css('min-height', thisWindowInnerHeight);
      }

      if ($('.content-slider').length > 0) {
        if ($('.content-slider').find('.js-content-slider-init').length > 0) {
          $('.js-content-slider-init').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            prevArrow: $('.slider-arrows .prev'),
            nextArrow: $('.slider-arrows .next'),
            responsive: [
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });
        }
        if ($('.content-slider').find('.js-content-slider-single').length > 0) {
          $('.js-content-slider-single').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: $('.slider-arrows-single .prev'),
            nextArrow: $('.slider-arrows-single .next')
          });
        }
        if ($('.module-5050-slider').find('.js-text-image-slider').length > 0) {
          $('.js-text-image-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: $('.module-5050-slider .slider-arrows .prev'),
            nextArrow: $('.module-5050-slider .slider-arrows .next')
          });
        }
      }

      if ($('.module-text-image-slider').find('.js-image-text-slider').length > 0) {
        $('.js-image-text-slider').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: '<button type="button" class="slick-next"><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.25 2.25L17 9L10.25 15.75M16.0625 9L2.375 9" stroke="black" stroke-width="2.8125" stroke-miterlimit="10" stroke-linecap="square"/></svg></button>',
          prevArrow: '<button type="button" class="slick-prev"><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.75 2.25L2 9L8.75 15.75M2.9375 9L16.625 9" stroke="black" stroke-width="2.8125" stroke-miterlimit="10" stroke-linecap="square"/></svg></button>', 
        });
      }

    }, // init()

    /**
     * Cache reusable data
     * @function cache
     * @memberof EnergyEx
     */

    _cache: function () {

      this.$win = $(window);
      this.$html = $('html');
      this.$body = $('body');
      this.$bodyHtml = $('body, html');
      this.$mainWrap = $('main');
      this.$header = $('.js-header');
      this.$homeIntro = $('.js-home-intro');
      this.$projects = $('.js-projects');
      this.$companies = $('.js-companies');
      this.$companies2 = $('.js-companies-2');
      this.$projectsNav = $('.js-projects-nav');
      this.$projectsAllFilterBtns = $('.js-project-filter');
      this.$companiesNav = $('.js-companies-nav');
      this.$allFilterBtns = $('.js-company-filter');
      this.$companies2Nav = $('.js-companies-2-nav');
      this.$allFilterBtns2 = $('.js-company-2-filter');
      this.$allFilterDropdowns = $('.js-company-filter-dropdown option');
      this.$projectItems = $('.js-project-item');
      this.$items = $('.js-company-item');
      this.$items2 = $('.js-company-2-item');
      this.$emptyInfo = $('.js-empty-info');
      this.$companiesWrap = $('.js-projects-wrap');
      this.$companiesWrap = $('.js-companies-wrap');
      this.$companies2Wrap = $('.js-companies-2-wrap');
      this.$mobileNav = $('.js-mobile-nav');
      this.$hamburger = $('.js-hamburger');
      this.$companiesMobile = $('.js-companies-mobile');
      this.$posts = $('.js-posts');
      this.$pagination = $('.js-pagination');
      this.$video = $('video');
      this.$partner = $('.partner__item');

    }, // _cache()

    /**
     * Attach event listeners
     * @function _events
     * @memberof EnergyEx
     */

    _events: function () {

      this.$html.on(
        'click',
        '.js-hamburger',
        this._showMobileNav.bind(this)
      );

      this.$html.on(
        'click',
        '.js-copy-link',
        this._copyLink.bind(this)
      );

      this.$html.on(
        'click',
        '.js-mobile-nav.is-active a:not([href^="#"])',
        this._hideMobileNav.bind(this)
      );

      this.$html.on(
        'click',
        '.js-mobile-nav.is-active a[href^="#"]',
        this._preventNavHashScroll.bind(this)
      );

      this.$html.on(
        'click',
        '.js-intro-scroll',
        this._scrollIntro.bind(this)
      );

      this.$html.on(
        'click change',
        '.js-company-filter, .js-company-filter-dropdown',
        this._filterCompanies.bind(this)
      );

      this.$html.on(
        'click',
        '.js-company-2-filter',
        this._filterCompanies2.bind(this)
      );

      this.$html.on(
        'click',
        '.js-project-filter',
        this._filterProjects.bind(this)
      );

      this.$html.on(
        'click',
        '.js-filter-reset',
        this._resetCompaniesFilter.bind(this)
      );

      this.$html.on(
        'click',
        '.js-tab-panel',
        this._tabPanelSwitch.bind(this)
      );

      if (this.$body.hasClass('home')) {
        this.$win.on(
          'scroll',
          this._homeIntroNav.bind(this)
        );
      }

      if (this.$body.hasClass('page-template-page-companies')) {
        this.$win.on(
          'scroll',
          this._companiesFiltersNav.bind(this)
        );
      }

      this.$html.on(
        'click',
        '.js-scroll-to a',
        this._scrollToSection.bind(this)
      );

      this.$html.on(
        'click',
        '.js-show-filters',
        this._showFilters.bind(this)
      );

      this.$homeIntro.one(
        'mousewheel',
        this._scrollIntro.bind(this)
      );

      this.$video.hover(function (event) {
        if (event.type === 'mouseenter') {
          $(this).attr('controls', '');
        } else if (event.type === 'mouseleave') {
          $(this).removeAttr('controls');
        }
      });

      this.$html.on(
        'click',
        '.video, .playpause',
        this._playVideo
      );

      this.$html.on(
        'click',
        '.js-video-external',
        this._hideOverlay
      );

      this.$html.on(
        'click',
        '.slider-close',
        this._closeSlider
      );

      this.$html.on(
        'click',
        '.js-video-item',
        this._playVideoItem
      );

      this.$html.on(
        'click',
        '.js-timeline-toggle',
        this._toggleTimeline
      );

      this.$html.on(
        'click',
        '.js-share',
        event => this._openShareWindow(event)
      );

      this.$html.on(
        'mouseover',
        '.eex-rebrand-header__nav--link.js-drawer-link-supported',
        event => this._handleNavDrawers(event)
      );

      this.$html.on(
        'click',
        '.home-discover__navigation__menu-item.js-discover-navigate',
        event => this._handleDiscoverNavContent(event)
      );

    }, // _events()

    _openShareWindow(event) {
      let link = $(event.currentTarget).attr('href');
      if (link.substr(0, 6) != 'mailto') {
        event.preventDefault();
        window.open(link, '', 'width=600, height=400');
      }
    },

    _initVideoSlider: function () {
      $('.js-video-slider').slick({
        infinite: false,
        mobileFirst: true,
        responsive: [{
          breakpoint: 959,
          settings: {
            slidesToShow: 3,
            infinite: true
          }
        }]
      });
    },

    _initPersonFeaturedSlider: function () {
      $('.js-feat-slider').slick({
        infinite: true,
        mobileFirst: true,
        arrows: true,
        dots: false,
        responsive: [{
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
          },
          breakpoint: 960,
          settings: {
            slidesToShow: 4,
          },
        }]
      });
    },

    _initPortalSliders: function() {
      var $mob_slider = $('.js-mobile-slider');
      var $mob_slider_settings = {
        infinite: false,
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        prevArrow: '<button type="button" class="slick-prev"><svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.148 0.587999L12.164 2.64L6.08 8.796L21.2 8.76V11.712H6.116L12.2 17.76L10.184 19.812L0.571999 10.236L10.148 0.587999Z" fill="black"></path></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.852 0.587999L9.836 2.64L15.92 8.796L0.8 8.76V11.712H15.884L9.8 17.76L11.816 19.812L21.428 10.236L11.852 0.587999Z" fill="black"></path></svg></button>',
        responsive: [{
          breakpoint: 700,
          settings: "unslick"
        }]
      };
      $mob_slider.not('.slick-initialized').slick($mob_slider_settings);
      $(window).on('resize', function () {
        $mob_slider.not('.slick-initialized').slick($mob_slider_settings);
      });
      $('.js-mob1-tab3-slider').slick({
        infinite: true,
        dots: false,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.148 0.587999L12.164 2.64L6.08 8.796L21.2 8.76V11.712H6.116L12.2 17.76L10.184 19.812L0.571999 10.236L10.148 0.587999Z" fill="black"></path></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.852 0.587999L9.836 2.64L15.92 8.796L0.8 8.76V11.712H15.884L9.8 17.76L11.816 19.812L21.428 10.236L11.852 0.587999Z" fill="black"></path></svg></button>',
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      });
    },

    _initResourcesScripts: function () {

      const sliderWrapper = $('.js-resources-slider');
      if (sliderWrapper.length) {
        sliderWrapper.slick({
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          infinite: true,
          //centerMode: true,
          nextArrow: '<button type="button" class="slick-next"><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.25 2.25L17 9L10.25 15.75M16.0625 9L2.375 9" stroke="black" stroke-width="2.8125" stroke-miterlimit="10" stroke-linecap="square"/></svg></button>',
          prevArrow: '<button type="button" class="slick-prev"><svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.75 2.25L2 9L8.75 15.75M2.9375 9L16.625 9" stroke="black" stroke-width="2.8125" stroke-miterlimit="10" stroke-linecap="square"/></svg></button>',
          responsive: [
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      }

      // const $search = $('.js-search')
      // const $button = $('.js-search-open')
      // const $searchInput = $('.js-search-input')
      // const $form = $('.search-bar__form')

      // $(document).on('click', '.js-search-open .icon-search', function (event) {
      //   event.preventDefault()
      //   if($(window).width() > 958) {
      //     $button.addClass('is-active').attr('aria-expanded', 'true')
      //     $search.addClass('is-active')
      //     $form.addClass('is-active')
      //     setTimeout(() => $searchInput[0].focus(), 500)
      //   }
      // });

      // $(document).on('click', '.js-search-open .icon-close', function (event) {
      //   $button.attr('aria-expanded', 'false').removeClass('is-active')
      //   $form.removeClass('is-active')
      //   $search.removeClass('is-active')
      // });

      // $(document).on('click', '.js-open-filters', function (event) {
      //   const filtersContainer = $('.resources__posts__filters');
      //   filtersContainer.toggleClass('is-active').slideToggle()
      //   if ( filtersContainer.hasClass('is-active') ) {
      //     $(this).attr('aria-expanded', 'true');
      //   } else {
      //     $(this).attr('aria-expanded', 'false');
      //   }
      // });
    },

    _initCohortScripts: function () {
      var $mob_slider = $('.js-mobile-slider');
      var $mob_slider_settings = {
        infinite: true,
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        dots: true,
        prevArrow: '<button type="button" class="slick-prev"><svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8H17M10 1L17 8L10 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17 8L1 8M8 15L1 8L8 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
        responsive: [{
          breakpoint: 1080,
          settings: "unslick"
        }]
      };
      $mob_slider.not('.slick-initialized').slick($mob_slider_settings);
      $(window).on('resize', function () {
        $mob_slider.not('.slick-initialized').slick($mob_slider_settings);
        let currCount = $('.js-carousel-counter').find('span');
        currCount.html('1');
      });
      $mob_slider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        let currCount = $(this).next('.js-carousel-counter').find('span');
        let currSlide = currentSlide + 1;
        currCount.html(currSlide);
      });
      $('.js-gallery-carousel').slick({
        infinite: true,
        dots: false,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        variableWidth: true,
        speed: 4000,
        cssEase: 'linear',
        responsive: [
          {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      });
      /* $(document).on('click', '.js-expand-details', function (event) {
        $(this).toggleClass('active');
        $(this).parent().next('.wysiwyg-editor').slideToggle('fast');
      }); */
      $(document).on('click', '.js-share-link', function (event) {
        let hash = $(this).closest('.cohort__card-section').attr('id');
        let url = window.location.href + '#' + hash
        navigator.clipboard.writeText(url);
      });
      $(document).on('click', '.js-flip-card', function (event) {
        $(this).toggleClass('flipped');
        $('.cohort__cards-sections').toggleClass('no-transform');
        //$('html').toggleClass('overflow');
        $('.hamburger').toggleClass('no-pointer-events');
        $(this).closest('.js-cards-wrap').toggleClass('section-has-flipped');
        $('.js-flip-card').addClass('has-been-flipped');
      });
      /* $(document).on('click', '.js-close-card', function (event) {
        $(this).parent().toggleClass('flipped');
        $(this).closest('.js-cards-wrap').toggleClass('section-has-flipped');
      }); */
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            entry.target.classList.add('visible');
          }
          else {
            entry.target.classList.remove('visible');
          }
        })
      })
      const boxElList = document.querySelectorAll('.js-flip-card');
      boxElList.forEach((el) => {
        observer.observe(el);
      })

      const cardsObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            $('body').addClass('animating');
          }
          else {
            $('body').removeClass('animating');
          }
        })
      })

      const cardSection = document.querySelectorAll('.cohort__cards-sections');
      cardSection.forEach((el) => {
        cardsObserver.observe(el);
      })

      gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

      /* setTimeout(() => {
        let panels = gsap.utils.toArray(".cohort__card-section"),
          scrollTween;

        panels.forEach((panel, i) => {
          ScrollTrigger.create({
            trigger: panel,
            start: "top bottom",
            end: "+=200%",
            scrub: 1,
            //onToggle: self => self.isActive && !scrollTween,
            snap: {
              snapTo: 1 / (panels.length - 1),
              duration: 1.5,
              inertia: false,
            },
          });
        });
      }, 1000) */

    },

    _initPortalEvents: function() {
      if ($('.js-events-list ul li').length == 0) {
        $('.js-events-list ul').append('<li class="js-no-events">Sorry, no events found. Check back soon!</li>');
      }
      $(document).on('click', '.js-viewall-pastevents', function (event) {
        event.preventDefault();
        $(this).siblings('ul').addClass('show-all');
      });
      $(document).on('click', '.js-events-all', function (event) {
        event.preventDefault();
        $(this).addClass('active');
        $('.js-events-portfolio').removeClass('active');
        $('.js-events-list').removeClass('portfolio-only');
        $('.js-no-events').show();
        $('.js-no-portfolio-events').remove();
      });
      $(document).on('click', '.js-events-portfolio', function (event) {
        event.preventDefault();
        $(this).addClass('active');
        $('.js-events-all').removeClass('active');
        $('.js-events-list').addClass('portfolio-only');
        $('.js-no-events').hide();
        if ($('.js-events-list ul li.portfolio').length == 0) {
          $('.js-events-list ul').append('<li class="js-no-portfolio-events">Sorry, no portfolio events found. Check back soon!</li>');
        }
      });
    },

    _initMobileSlider: function () {
      var $mob_slider = $('.js-logos-slider');
      var $mob_slider_settings = {
        infinite: true,
        mobileFirst: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: false,
        prevArrow: '<button type="button" class="slick-prev"><svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg"><title>E510AA1D-8A6C-444B-A22E-A0FE9ACCD0DF</title><path d="M.915.043L-.043 1 5 6.043 10.043 1 9.085.043 5 4.128z" fill-rule="nonzero" fill="#fff"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg"><title>E510AA1D-8A6C-444B-A22E-A0FE9ACCD0DF</title><path d="M.915.043L-.043 1 5 6.043 10.043 1 9.085.043 5 4.128z" fill-rule="nonzero" fill="#fff"/></svg></button>',
        responsive: [{
          breakpoint: 700,
          settings: "unslick"
        }]
      };
      $mob_slider.not('.slick-initialized').slick($mob_slider_settings);
      $(window).on('resize', function() {
        $mob_slider.not('.slick-initialized').slick($mob_slider_settings);
      });
    },

    _initNavSlider: function () {
      var $nav_slider = $('.js-nav-slider');
      var $nav_slider_settings = {
        infinite: true,
        mobileFirst: true,
        dots: false,
        prevArrow: '<button type="button" class="slick-prev"><svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg"><title>E510AA1D-8A6C-444B-A22E-A0FE9ACCD0DF</title><path d="M.915.043L-.043 1 5 6.043 10.043 1 9.085.043 5 4.128z" fill-rule="nonzero" fill="#fff"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg"><title>E510AA1D-8A6C-444B-A22E-A0FE9ACCD0DF</title><path d="M.915.043L-.043 1 5 6.043 10.043 1 9.085.043 5 4.128z" fill-rule="nonzero" fill="#fff"/></svg></button>',
        responsive: [{
          breakpoint: 700,
          settings: "unslick"
        }]
      };
      $nav_slider.not('.slick-initialized').slick($nav_slider_settings);
      $(window).on('resize', function() {
        $nav_slider.not('.slick-initialized').slick($nav_slider_settings);
      });
    },

    _initTooltips: function () {
      $(".module-5050-text-text__item a[href*='#']").each(function () {
        let el = $(this);
        let id = $(this).attr('href');
        let tooltip = '<span>'+$(id).html()+'</span>';
        el.addClass('js-tooltip');
        el.append(tooltip);
        $(id).remove();
      });
      $(document).on('click', '.js-tooltip', function (event) {
        event.preventDefault();
        //$(this).toggleClass('active');
      });
      /* $(document).on("click", function (event) {
        if ($('.js-tooltip').length) {
          if ($(event.target).closest(".js-tooltip").length === 0) {
            $('.js-tooltip').removeClass('active');
          }
        }
      }); */
      $(".js-tooltip").hover(function () {
        $(this).addClass("active");  //Add the active class to the area is hovered
      }, function () {
        $(this).removeClass("active");
      });
    },

    _initSal: function () {
      sal();
    },

    _initSmoothScroll() {
      $(document).on('click', '.page-rebrand__hero a[href^="#"]', function (event) {
          event.preventDefault();  
          $('html, body').animate({
              scrollTop: $($.attr(this, 'href')).offset().top - 150
          }, 400);
      });
    },

    _initModuleCarousel() {
      if ($('.js-sectors').length ) {

      }
      $(document).on('click', '.js-sector-item', function (event) {
        let el = $(this);
        event.preventDefault();
        if ( !el.parent().hasClass('active') ) {
          $('.js-sector-item').parent().removeClass('active');
          $(this).parent().addClass('active');
        }
      });
      $(document).on('click', '.js-sector-next', function (event) {
        let el = $(this);
        event.preventDefault();
        el.parent().parent().removeClass('active');
        if ( el.parent().parent().is(":last-child") ) {
          el.parent().parent().siblings().first().addClass('active');
        } else {
          el.parent().parent().next().addClass('active');
        }
      });
      $(document).on('click', '.js-sector-prev', function (event) {
        let el = $(this);
        event.preventDefault();
        el.parent().parent().removeClass('active');
        if (el.parent().parent().is(":first-child")) {
          el.parent().parent().siblings().last().addClass('active');
        } else {
          el.parent().parent().prev().addClass('active');
        }
      });
    },

    _initAccordion() {
      $( '.js-accordion-content' ).hide();
      $( '.js-accordion-content' ).attr( 'id', function( IDcount ) {
        return 'panel-' + IDcount;
      });
      $( '.js-accordion-content' ).attr( 'aria-labelledby', function( IDcount ) {
        return 'control-panel-' + IDcount;
      });
      $( '.js-accordion-content' ).attr( 'hidden', 'true' );
      $( '.js-accordion-list .js-accordion-content' ).attr( 'role' , 'region' );
      $( '.js-accordion-title' ).each(function(i){
        var $target = $(this).next( '.js-accordion-content' )[0].id;
        var $link = $( '<button>', {
          'aria-expanded': 'false',
          'aria-controls': $target,
          'id' : 'control-' + $target
        });
        $(this).wrapInner($link);
      });
  
      $( '.js-accordion-title button' ).append('<span class="icon"><svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.5 1V11M1.5 6H11.5" stroke="#424242" stroke-width="2" stroke-linecap="round"/></svg>      </span>');
  
      /* $( '.js-accordion-list .js-accordion-title:first-of-type button' ).each(function() {
        $(this).parents( '.js-accordion-list' ).find( '[aria-expanded=true]' ).attr( 'aria-expanded' , false ).removeClass( 'active' ).parent().next( '.js-accordion-content' ).slideUp(300).attr( 'hidden', 'true' );
        $(this).attr( 'aria-expanded' , true ).addClass( 'active' ).parent().next( '.js-accordion-content' ).show().removeAttr( 'hidden');
      }); */
  
      $( '.js-accordion-title button' ).click(function() {
        if ($(this).attr( 'aria-expanded' ) == 'false'){
          var $href = $(this).attr('aria-controls');
          $(this).parents( '.js-accordion-list' ).find( '[aria-expanded=true]' ).attr( 'aria-expanded' , false ).removeClass( 'active' ).parent().next( '.js-accordion-content' ).slideUp(300).attr( 'hidden', 'true');
          $(this).attr( 'aria-expanded' , true ).addClass( 'active' ).parent().next( '.js-accordion-content' ).slideDown(300).removeAttr( 'hidden');
        } else {
          var $href = $(this).attr('aria-controls');
          $(this).attr( 'aria-expanded' , false ).removeClass( 'active' ).parent().next( '.js-accordion-content' ).slideUp(300).attr( 'hidden', 'true');
        }
        return false;
      });
    },

    _initCompaniesFilters() {
      $('.js-cportfolio-item').each(function() {
        let nextItem = $(this).next('.js-cportfolio-item').find('.cportfolio__main__list__item__title h5').text();
        let modal = $(this).find('a').attr('href');
        let nextBtn = $(modal).find('.js-next-company');
        if ( nextItem.length ) {
          $(nextBtn).text(nextItem);
        } else {
          let firstItem = $('.js-portfolio-list').find('.js-cportfolio-item:first .cportfolio__main__list__item__title h5').text();
          let modal = $(this).find('a').attr('href');
          let nextBtn = $(modal).find('.js-next-company');
          $(nextBtn).text(firstItem);
        }
      });
      $("#search").on("input", function(){
        if($('#search').val()) {
          $('.js-portfolio-list').addClass('search-active');
          $('.js-cportfolio-clear').show();
        } else {
          $('.js-portfolio-list').removeClass('search-active');
          if ( !$(".js-cportfolio-check:checked").length ) {
            $('.js-cportfolio-clear').hide();
          }
        }
      });
      $('.js-cportfolio-dropdown').on('click', function() {
        $(this).next('.js-cportfolio-checks').slideToggle();
        $(this).toggleClass('active');
      });
      /* $('.js-cportfolio-clear').on('click', function(e) {
        e.preventDefault();
        $('#search').val('');
        $('.js-cportfolio-check').prop( "checked", false );
        $('.js-portfolio-list').removeClass('search-active');
        $(this).hide();
      }); */
      $('.js-cportfolio-check').on('change', function() {
        if ( (!$(".js-cportfolio-check:checked").length) && (!$('#search').val()) ) {
          $('.js-cportfolio-clear').hide();
        } else {
          $('.js-cportfolio-clear').show();
        }
      });
      if (document.location.pathname.split(/\/(?=.)/).length == 3) {
        let loc = window.location.pathname;
        let subid = loc.split('/')[2];
        let id   = '#'+subid;
        if ( $(id).length ) {
          $('a[href="'+id+'"]').click();
          /* $.magnificPopup.open({
            items: {
              src: id,
              type: 'inline',
              midClick: true,
              removalDelay: 100,
              mainClass: 'popup--slide-in',
              gallery: {
                enabled: true,
              },
              callbacks: {
                open: function() {
                  let id = $(this.st.el).attr('href').substr(1);
                  window.history.pushState({}, '', '/companies/'+id);
                },
                change: function() {
                  let id = $(this.content).attr('id');
                  window.history.pushState({}, '', '/companies/'+id);
                },
                close: function() {
                  window.history.replaceState({}, '', '/companies/');
                }
              }
            }
          }); */
        }
      }
    },

    _initHeroSlider: function () {

      $($('body').find('.home-hero-rebrand--js-slider')).slick({
        dots: true,
        speed: 500,
        arrows: false,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnHover: false,
        pauseOnFocus: true,
        fade: true
      });

      // Using Slick dots li element as a clickable element instead of clicking on the actual btn element directly
      $($('body').find('.home-hero-rebrand--js-slider .slick-dots li')).on('click', function() {
        $(this).find('button').one('click');
      });

      if ($(window).width() < 701) {
        let thisWindowInnerheight = window.innerHeight;
        let homeHeroInnerSlide    = $('.home-hero-rebrand--inner-slide');
        homeHeroInnerSlide.height(thisWindowInnerheight); 
      }

    },

    _initResourcesSlider: function () {
      $('.js-resources-slider').slick({
        infinite: false,
        mobileFirst: true,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg"><title>E510AA1D-8A6C-444B-A22E-A0FE9ACCD0DF</title><path d="M.915.043L-.043 1 5 6.043 10.043 1 9.085.043 5 4.128z" fill-rule="nonzero" fill="#27292A"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg"><title>E510AA1D-8A6C-444B-A22E-A0FE9ACCD0DF</title><path d="M.915.043L-.043 1 5 6.043 10.043 1 9.085.043 5 4.128z" fill-rule="nonzero" fill="#27292A"/></svg></button>',
        responsive: [{
            breakpoint: 700,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 4
            }
          }
        ]
      });
    },

    _initInnovationSlider: function () {
      $('.js-innovation-slider').slick({
        infinite: true,
        mobileFirst: true,
        arrows: true,
        adaptiveHeight: true,
        prevArrow: '<button type="button" class="slick-prev slick-prev--inno"><svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg"><title>E510AA1D-8A6C-444B-A22E-A0FE9ACCD0DF</title><path d="M.915.043L-.043 1 5 6.043 10.043 1 9.085.043 5 4.128z" fill-rule="nonzero" fill="#27292A"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next slick-prev--inno"><svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg"><title>E510AA1D-8A6C-444B-A22E-A0FE9ACCD0DF</title><path d="M.915.043L-.043 1 5 6.043 10.043 1 9.085.043 5 4.128z" fill-rule="nonzero" fill="#27292A"/></svg></button>',
      });
    },

    _initCompaniesSlider: function () {
      const slider = $(".js-companies-slider");
      slider
        .slick({
          infinite: true,
          mobileFirst: true,
          slidesToShow: 1,
          arrows: true,
          prevArrow: '<button type="button" class="slick-prev"><svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg"><title>E510AA1D-8A6C-444B-A22E-A0FE9ACCD0DF</title><path d="M.915.043L-.043 1 5 6.043 10.043 1 9.085.043 5 4.128z" fill-rule="nonzero" fill="#fff"/></svg></button>',
          nextArrow: '<button type="button" class="slick-next"><svg width="10" height="6" viewBox="0 0 10 6" xmlns="http://www.w3.org/2000/svg"><title>E510AA1D-8A6C-444B-A22E-A0FE9ACCD0DF</title><path d="M.915.043L-.043 1 5 6.043 10.043 1 9.085.043 5 4.128z" fill-rule="nonzero" fill="#fff"/></svg></button>',
          responsive: [{
            breakpoint: 992,
            settings: {
              slidesToShow: 3
            }
          }]
        }).on({
          beforeChange: function (event, slick, currentSlide, nextSlide) {
            var currentSlideColor = slick.$slides.eq(currentSlide)
            var nextSlideColor = slick.$slides.eq(nextSlide)
            $('.js-companies-slider .slick-slide').each(function () {
              var slideColor = $(this).find('.companies-2__slider__slide').data('color');
              $(this).attr('data-color', slideColor);
            });
            $('body').removeClass(currentSlideColor.data('color') + '-current').addClass(nextSlideColor.data('color') + '-current')
          }
        });

      //slider.on('wheel', (function(e) {
      //  e.preventDefault();
      //  if (e.originalEvent.deltaY < 0) {
      //    $(this).slick('slickNext');
      //  } else {
      //    $(this).slick('slickPrev');
      //  }
      // }));
    },

    _handleNavDrawers: function(event) {
      let drawer_id = $(event.currentTarget).data('drawer-id');
      $('.eex-rebrand-header__nav--drawer-wrapper').css('display', 'none');
      $('.eex-rebrand-header__nav--drawer-wrapper[data-drawer-id=' + drawer_id + ']').css('display', 'flex');

      $('.eex-rebrand-header').find('.eex-rebrand-header--logo-black').addClass('hide');
      $('.eex-rebrand-header').find('.eex-rebrand-header--logo-white').addClass('hide');
      $('.eex-rebrand-header').find('.eex-rebrand-header--logo-color').addClass('show');

      $('.eex-rebrand-header--wrapper--full').addClass('bg-white');
      
      $('.eex-rebrand-header--wrapper--full').addClass('u-border-bottom-none');

      $('.eex-rebrand-header__nav--drawer-wrapper-fluid').addClass('u-border-bottom-header-drawer');   
      
      

      $('.eex-rebrand-header__nav--drawer-wrapper-fluid').on('mouseleave', () => {
        $('.eex-rebrand-header__nav--drawer-wrapper').css('display', 'none');
        // Change logo's color
        $('.eex-rebrand-header').find('.eex-rebrand-header--logo-black').removeClass('hide');
        $('.eex-rebrand-header').find('.eex-rebrand-header--logo-white').removeClass('hide');
        $('.eex-rebrand-header').find('.eex-rebrand-header--logo-color').removeClass('show');
        // Add BG white when Nav is open
        $('.eex-rebrand-header--wrapper--full').removeClass('bg-white');
        // Remove border bottom none class when closing drawer
        $('.eex-rebrand-header--wrapper--full').removeClass('u-border-bottom-none');    
        // Remove drawer wrapper border bottom when closing drawer
        $('.eex-rebrand-header__nav--drawer-wrapper-fluid').removeClass('u-border-bottom-header-drawer');   
      });
    },

    _handleDiscoverNavContent: function (event) {

      let thisClickedEl = $(event.currentTarget);
      let thisClickedElSectionID = thisClickedEl.data('discover-section-id');
      let allSectionElsMenuItems = $('.home-discover__navigation__menu-item.js-discover-navigate');
      let allHomeDiscoverSectionsContent = $('.home-discover__section');
      let thisHomeDiscoverSectionContent = $('.home-discover__section[data-discover-section-id=' + thisClickedElSectionID +']');
      console.log("🚀 ~ file: scripts.js ~ line 463 ~ thisHomeDiscoverSectionContent", thisHomeDiscoverSectionContent)

      // Show current clicked discover section content

      // First hide all tabs 
      allHomeDiscoverSectionsContent.removeClass('show');
      allHomeDiscoverSectionsContent.addClass('hide');
      allSectionElsMenuItems.removeClass('border-color-black');
      // Then show clicked section content
      thisHomeDiscoverSectionContent.removeClass('hide');
      thisHomeDiscoverSectionContent.addClass('show');
      // Add active tab border
      thisClickedEl.addClass('border-color-black');
    },

    _toggleTimeline: function (e) {
      e.preventDefault();
      var linkItem = $(e.currentTarget);
      var href = linkItem.attr("href");
      $('.js-timeline-toggle').removeClass('active');
      $('.js-timeline-content').removeClass('active');
      linkItem.addClass('active');
      $(href).addClass('active');
    },

    _playVideoItem: function (e) {
      e.preventDefault();
      var videoItem = $(e.currentTarget);
      var videoType = videoItem.data('type');
      var videoDate = videoItem.data('date');
      var videoTitle = videoItem.data('title');
      var videoSrc = videoItem.data('video');

      if (videoType == 'external') {
        var markup = '<div class="mfp-video-header">' +
          `<h5 class="video-item__time">&nbsp;</h5>` +
          `<h3 class="video-item__title">${videoTitle}</h3>` +
          '<div class="mfp-close"></div>' +
          '</div>' +
          '<div class="mfp-iframe-scaler">' +
          '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
          '</div>'
        if (videoDate) {
          markup = '<div class="mfp-video-header">' +
            `<h5 class="video-item__time">${videoDate}</h5>` +
            `<h3 class="video-item__title">${videoTitle}</h3>` +
            '<div class="mfp-close"></div>' +
            '</div>' +
            '<div class="mfp-iframe-scaler">' +
            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
            '</div>'
        }
        $.magnificPopup.open({
          items: {
            src: videoItem.attr('href'),
            type: 'iframe',
          },
          midClick: true,
          removalDelay: 100,
          mainClass: 'popup--animate',
          iframe: {
            markup: markup, // HTML markup of popup, `mfp-close` will be replaced by the close button
            patterns: {
              youtube: {
                index: 'youtube.com',
                id: 'v=',
                src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1'
              }
            }
          }
        });
      } else if (videoType == 'internal') {

        $.magnificPopup.open({
          items: {
            src: '#video-popup',
            type: 'inline',
          },
          midClick: true,
          removalDelay: 100,
          mainClass: 'popup--animate',
          callbacks: {
            open: function () {
              $('html').css('margin-right', 0);
              $(this.content).find('h5').text(videoDate);
              $(this.content).find('h3').text(videoTitle);
              $(this.content).find('source').attr('src', videoSrc);
              $(this.content).find('video')[0].load();
              $(this.content).find('video')[0].play();
            },
            close: function () {
              $(this.content).find('video')[0].load();
            }
          }
        });
      } else {
        return false;
      }

    },

    _utilSlugify: function (str) {
      str = str.replace(/^\s+|\s+$/g, '');
      str = str.toLowerCase();

      var from = "àáäâèéëêìíïîòóöôùúüûñç·/,:;";
      var to = "aaaaeeeeiiiioooouuuunc------";
      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '_')
        .replace(/-+/g, '_');

      return str;
    },
    _utilObjectLength(object) {
      var length = 0;
      for (var key in object) {
        if (object.hasOwnProperty(key)) {
          ++length;
        }
      }
      return length;
    },
    _setIntroHeight: function () {
      var viewportHeight = this.$win.height();
      this.$homeIntro.css({
        height: viewportHeight
      });
    },

    _setNavOffset: function () {
      var scrollTop     = $(window).scrollTop(),
        elementOffset = $('.js-header').offset().top,
        distance      = (elementOffset - scrollTop + 100);
      if ($(".home-intro").height() > 0 && $(".home-intro:not(.is-hidden)").length) {
        $('.nav--mobile').css('top', distance).css('transition', 'none');
      } else {
        $('.nav--mobile').removeAttr( 'style' );
      }
    },

    _initMasonry: function () {
      var $grid = $('.masonry-grid');
      $grid.masonry({
        itemSelector: '.masonry-item',
        columnWidth: '.masonry-item',
        gutter: 20,
        percentPosition: true
      });
      $grid.imagesLoaded().progress(function () {
        $grid.masonry('layout');
      });
    },

    _initMultistepForm: function () {
      $(document).on("click", "#input_29_2 li", function () {
        $(this).find("input").prop("checked", true);
        $("#gform_next_button_29_101").trigger("click");
      });
      $(document).on("click", "#input_33_2 li", function () {
        $(this).find("input").prop("checked", true);
        $("#gform_next_button_33_101").trigger("click");
      });
      $(document).on("click", "#input_36_2 li", function () {
        $(this).find("input").prop("checked", true);
        $("#gform_next_button_36_101").trigger("click");
      });
      $(document).on('gform_confirmation_loaded', function (event, formId) {
        if (formId == 29 || formId == 33 || formId == 36) {
          $('.multistep-form__intro').hide();
          $('.heading--section').hide();
        }
      });
      $(document).on('gform_page_loaded', function (event, form_id, current_page) {
        if ( form_id == 29 ) {
          if (current_page == 2) {
            $('#gform_page_29_1').addClass("completed");
            $('html,body').animate({
              scrollTop: $('#gform_page_29_2').offset().top + 100
            }, 'slow');
          }
          if (current_page == 3) {
            $('#gform_page_29_1').addClass("completed");
            $('#gform_page_29_2').addClass("completed");
            $('html,body').animate({
              scrollTop: $('#gform_page_29_3').offset().top - 100
            }, 'slow');
          }
          if (current_page == 4) {
            $('#gform_page_29_1').addClass("completed");
            $('#gform_page_29_2').addClass("completed");
            $('#gform_page_29_3').addClass("completed");
            $('html,body').animate({
              scrollTop: $('#gform_page_29_4').offset().top - 550
            }, 'slow');
          }
        }
        if ( form_id == 33 ) {
          if (current_page == 2) {
            $('#gform_page_33_1').addClass("completed");
            $('html,body').animate({
              scrollTop: $('#gform_page_33_2').offset().top + 100
            }, 'slow');
          }
          if (current_page == 3) {
            $('#gform_page_33_1').addClass("completed");
            $('#gform_page_33_2').addClass("completed");
            $('html,body').animate({
              scrollTop: $('#gform_page_33_3').offset().top - 100
            }, 'slow');
          }
          if (current_page == 4) {
            $('#gform_page_33_1').addClass("completed");
            $('#gform_page_33_2').addClass("completed");
            $('#gform_page_33_3').addClass("completed");
            $('html,body').animate({
              scrollTop: $('#gform_page_33_4').offset().top - 550
            }, 'slow');
          }
        }
        if ( form_id == 36 ) {
          if (current_page == 2) {
            $('#gform_page_36_1').addClass("completed");
            $('html,body').animate({
              scrollTop: $('#gform_page_36_2').offset().top + 100
            }, 'slow');
          }
          if (current_page == 3) {
            $('#gform_page_36_1').addClass("completed");
            $('#gform_page_36_2').addClass("completed");
            $('html,body').animate({
              scrollTop: $('#gform_page_36_3').offset().top - 100
            }, 'slow');
          }
          if (current_page == 4) {
            $('#gform_page_36_1').addClass("completed");
            $('#gform_page_36_2').addClass("completed");
            $('#gform_page_36_3').addClass("completed");
            $('html,body').animate({
              scrollTop: $('#gform_page_36_4').offset().top - 550
            }, 'slow');
          }
        }
      });
    },

    _initInfiniteScroll: function () {
      this.$posts.jscroll({
        loadingHtml: '<img class="post-item__loading" src="/wp-content/themes/_eex/img/icon-loading.svg">',
        padding: 10,
        autotrigger: true,
        nextSelector: '.js-pagination a',
        contentSelector: '.js-posts'
      });
    },


    _showSplash: function (e) {
      $.magnificPopup.open({
        items: {
          src: '#splash-info',
          type: 'inline'
        }
      });
    },


    _subscriptionMessage: function (e) {
      if ($('.subscription-form .error').length || $('.subscription-form .success').length) {
        $.magnificPopup.open({
          items: {
            src: '#subscription-form',
            type: 'inline'
          }
        });
      }
    },

    _addSmoothScroll: function () {
      $('.alt-content-rows__nav a[href^="#"]').parent().addClass('js-scroll-to');
    },

    _closeSlider: function (e) {
      e.preventDefault();
      $('.splash-slider').slideToggle(300);
    },

    _showFilters: function (e) {
      e.preventDefault();
      this.$companiesMobile.toggleClass('is-hidden');
    },

    _scrollToSection: function (e) {

      var url = $(e.currentTarget).attr('href').split('#');
      var el = $('#' + url[1]);

      if (el.length) {
        this.$bodyHtml.animate({
          scrollTop: el.offset().top - 50
        }, 200);
      }
    },

    _initScrollspy: function (e) {
      var spy = new ScrollSpy('.nav--main', {
        nav: '.menu-item-20 .sub-menu > li > a',
        className: 'is-inview'
      });
    },

    _initPopups: function () {
      $('.js-popup').magnificPopup({
        type: 'inline',
        midClick: true,
        removalDelay: 100,
        mainClass: 'popup--animate'
      });
      $('.js-cportfolio-popup').magnificPopup({
        type: 'inline',
        midClick: true,
        removalDelay: 100,
        mainClass: 'popup--slide-in',
        gallery: {
          enabled: true,
        },
        callbacks: {
          open: function() {
            let id = $(this.st.el).attr('href').substr(1);
            window.history.pushState({}, '', '/companies/'+id);
          },
          change: function() {
            let id = $(this.content).attr('id');
            window.history.pushState({}, '', '/companies/'+id);
          },
          close: function() {
            window.history.replaceState({}, '', '/companies/');
          }
        }
      });
      $('.js-next-slide').on('click', function(e) {
        $('.js-cportfolio-popup').magnificPopup('next');
        e.stopPropagation();
      });
    },

    _showPartner: function (e) {
      e.preventDefault()
      var partnerItem = this.target.hash;
      $.magnificPopup.open({
        items: {
          src: $(this.target.hash),
          type: 'inline'
        }
      });
    },

    _homeIntroNav: function () {
      if (this.$win.scrollTop() >= this.$mainWrap.offset().top) {
        this.$header.removeClass('header--home');
        this.$mainWrap.removeClass('main--home');
        this.$homeIntro.addClass('is-hidden');
      }
    },

    _scrollIntro: function (e) {
      e.preventDefault();
      this.$bodyHtml.animate({
        scrollTop: $('main').position().top
      }, 300, function () {
        $('.js-home-intro').animate({
          height: 0
        }, 10);
      });
    },

    _companiesFiltersNav: function () {
      if (this.$win.scrollTop() >= this.$companies.offset().top - 100) {
        this.$companiesNav.addClass('is-sticky');
      } else {
        this.$companiesNav.removeClass('is-sticky');
      }
    },

    _initHeadroom: function () {
      var theHeader = document.querySelector('.js-header');
      var headroom = new Headroom(theHeader, {
        onTop: function () {
          document.querySelector('.js-mobile-nav').classList.add("nav-top");
        },
        onNotTop: function () {
          document.querySelector('.js-mobile-nav').classList.remove("nav-top");
        },
      });
      headroom.init();
    },

    _initHeadroomHome: function () {
      var theHeader = document.querySelector('.js-header--eex-rebrand');
      var headroom = new Headroom(theHeader, {
        onTop: function () {
          //document.querySelector('.js-mobile-nav').classList.add("nav-top");
        },
        onNotTop: function () {
          //document.querySelector('.js-mobile-nav').classList.remove("nav-top");
        },
      });
      headroom.init();
    },

    _tabPanelSwitch: function (e) {
      e.preventDefault();
      var el = $(e.currentTarget);
      var id = el.attr('href');
      $('.js-tab-panel').removeClass('active');
      $(el).addClass('active');
      $('.team-list-wrap').fadeOut(400).removeClass('active');
      $(id).fadeIn(400);
    },

    _filterCompanies: function (e) {
      var el = $(e.currentTarget);
      var filters = [];
      var selected = [];

      this.$bodyHtml.animate({
        scrollTop: this.$companies.offset().top - 100
      }, 200);


      el.toggleClass('is-active');
      this.$emptyInfo.addClass('is-hidden');

      this.$items.removeClass('is-selected is-hidden');

      this.$allFilterBtns.filter('.is-active').each(function () {
        filters.push(this.dataset.filter);
      });

      this.$allFilterDropdowns.filter(':selected').each(function () {
        if (this.value.length > 0) {
          filters.push(this.value);
        }
      });

      if (filters.length > 0) {
        var filterClasses = filters.join('');
        this.$items.addClass('is-hidden');

        this.$items.filter(filterClasses).each(function () {
          $(this).removeClass('is-hidden').addClass('is-selected');
          selected.push(this);
        });

        if (selected.length == 0) {
          this.$emptyInfo.removeClass('is-hidden');
        }
      } else {
        this.$items.removeClass('is-hidden');
        this.$emptyInfo.addClass('is-hidden');
      }
    },

    _resetCompaniesFilter: function (e) {

      this.$items2.removeClass('is-selected is-hidden');
      this.$allFilterBtns2.removeClass('is-active');
      this.$emptyInfo.addClass('is-hidden');
      $('.js-filter-label p').remove();
      $('.js-filter-labels p').remove();
      this.$allFilterBtns2.filter('.is-active').each(function () {
        filters.push(this.dataset.filter);
      });

    },

    _filterCompanies2: function (e) {
      var el = $(e.currentTarget);
      var filters = [];
      var selected = [];

      el.parent().siblings().find('.js-company-2-filter').removeClass('is-active');
      el.toggleClass('is-active');
      this.$emptyInfo.addClass('is-hidden');

      this.$items2.removeClass('is-selected is-hidden');

      $('.js-filter-label p').remove();
      $('.js-filter-labels p').remove();

      this.$allFilterBtns2.filter('.is-active').each(function () {
        filters.push(this.dataset.filter);
        $(this).parent().parent().siblings('.js-filter-label').append('<p><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="12" height="12" rx="4" fill="#3885E7"/><path d="M4.5 8.10005L2.4 6.00005L1.7 6.70005L4.5 9.50005L10.5 3.50005L9.8 2.80005L4.5 8.10005Z" fill="white"/></svg>'+$(this).text()+'</p>');
        $('.js-filter-labels').append('<p><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="12" height="12" rx="4" fill="#3885E7"/><path d="M4.5 8.10005L2.4 6.00005L1.7 6.70005L4.5 9.50005L10.5 3.50005L9.8 2.80005L4.5 8.10005Z" fill="white"/></svg>'+$(this).text()+'</p>');
      });

      if (filters.length > 0) {
        var filterClasses = filters.join('');
        this.$items2.addClass('is-hidden');

        this.$items2.filter(filterClasses).each(function () {
          $(this).removeClass('is-hidden').addClass('is-selected');
          selected.push(this);
        });

        if (selected.length == 0) {
          this.$emptyInfo.removeClass('is-hidden');
        }
      } else {
        this.$items2.removeClass('is-hidden');
        this.$emptyInfo.addClass('is-hidden');
      }
    },

    _filterProjects: function (e) {
      var el = $(e.currentTarget);
      var filters = [];
      var selected = [];
      el.parent().siblings().children().removeClass('is-active');
      el.toggleClass('is-active');
      this.$emptyInfo.addClass('is-hidden');

      this.$projectItems.removeClass('is-selected is-hidden');

      this.$projectsAllFilterBtns.filter('.is-active').each(function () {
        filters.push(this.dataset.filter);
      });

      if (filters.length > 0) {
        var filterClasses = filters.join('');
        this.$projectItems.addClass('is-hidden');

        this.$projectItems.filter(filterClasses).each(function () {
          $(this).removeClass('is-hidden').addClass('is-selected');
          selected.push(this);
        });

        if (selected.length == 0) {
          this.$emptyInfo.removeClass('is-hidden');
        }
      } else {
        this.$projectItems.removeClass('is-hidden');
        this.$emptyInfo.addClass('is-hidden');
      }
      document.querySelector('.js-projects-wrap').scrollIntoView({
        behavior: 'smooth'
      });
    },

    _searchProjects: function () {
      jQuery.expr[':'].contains = function (a, index, obj) {
        return jQuery(a).text().toUpperCase()
          .indexOf(obj[3].toUpperCase()) >= 0;
      };

      function searchProjects() {
        var projectSearch = document.getElementById("js-project-search");
        var s = projectSearch.value;
        $('.js-project-item').show();
        $('.js-project-item:not(:contains("' + s + '"))').hide();
      }

      $("#js-project-search").keyup(function (e) {
        searchProjects();
        if ($(".js-project-item:visible").length === 0) {
          $('.js-empty-info').removeClass('is-hidden');
        } else {
          $('.js-empty-info').addClass('is-hidden');
        }
        if (e.which === 13) { // 13 is the keycode for Enter
          document.querySelector('.js-projects-wrap').scrollIntoView({
            behavior: 'smooth'
          });
        }
      });
    },

    _copyLink: function(event) {
      event.preventDefault();
      navigator.clipboard.writeText(window.location.href);
      console.log('copied')
      $('.js-copy-link').addClass('active');
      setTimeout(() => {
        $('.js-copy-link').removeClass('active');
      }, 1000);
    },

    _showMobileNav: function (e) {
      console.log('show mobile nav');
      e.preventDefault();
      this.$mobileNav.toggleClass('is-active');
      this.$hamburger.toggleClass('is-active');
      this.$body.toggleClass('u-prevent-scroll');

      if(this.$hamburger.hasClass('is-active')) {
        $('.eex-rebrand-header__nav-mobile-container').height(window.innerHeight);
      }

      if ($('.eex-rebrand-header--wrapper--full').hasClass('bg-white')) {
        $('.eex-rebrand-header--wrapper--full, .eex-rebrand-header--wrapper').removeClass('bg-white');
        $('.eex-rebrand-header--wrapper--full').removeClass('border-bottom-black');
        $('.eex-rebrand-header').find('.eex-rebrand-header--logo-black').removeClass('hide');
        $('.eex-rebrand-header').find('.eex-rebrand-header--logo-color').removeClass('show');

        $('html, body').removeClass('u-prevent-scroll');

        $('.eex-rebrand-header__nav-mobile-container').hide();

      } else {
        $('.eex-rebrand-header--wrapper--full, .eex-rebrand-header--wrapper').addClass('bg-white');
        $('.eex-rebrand-header--wrapper--full').addClass('border-bottom-black');
        $('.eex-rebrand-header').find('.eex-rebrand-header--logo-black').addClass('hide');
        $('.eex-rebrand-header').find('.eex-rebrand-header--logo-color').addClass('show');

        $('html, body').addClass('u-prevent-scroll');

        $('.eex-rebrand-header__nav-mobile-container').show();
      }

      /** Handle Mobile Open & Close Submenus */

      // $('.eex-rebrand-header__nav-mobile--li .js-open-submenu').on('click', (event) => {
      //   let thisSubmenuID = $(event.currentTarget).data('submenu-id');
      //   let thisSubNavEl  = $('ul.eex-rebrand-header__nav-mobile--ul-nested[data-submenu-id=' + thisSubmenuID + ']');
      //   let el = $(event.target);
      //   el.parent('li').toggleClass('is-active');
      //   // Show submenu
      //   thisSubNavEl.toggleClass('show');
      // });

      $('.eex-rebrand-header__nav-mobile--li.js-open-submenu').on('click', function() {
        let thisSubmenuID = $(this).data('submenu-id');
        let thisSubNavEl  = $('ul.eex-rebrand-header__nav-mobile--ul-nested[data-submenu-id=' + thisSubmenuID + ']');
        // Show submenu
        let hamburger = $(this).find('.eex-rebrand-header__nav-mobile--hamburger');
        if(hamburger.length) {
          thisSubNavEl.addClass('show');
          $('.eex-rebrand-header__nav-mobile--back').on('click', function(e) {
            e.stopPropagation()
            thisSubNavEl.removeClass('show');
          });
        } else {
          thisSubNavEl.slideToggle();
          $(this).toggleClass('is-active');
        }
      });

    },

    _hideMobileNav: function (e) {
      console.log('hide mobile nav called');
      this.$mobileNav.removeClass('is-active');
      this.$hamburger.removeClass('is-active');
      this.$body.removeClass('u-prevent-scroll');
    },

    _preventNavHashScroll: function (e) {
      e.preventDefault();
    },

    _vivus: function () {

      if ($('.js-program-drawing').length && this.$html.hasClass('ua-desktop')) {
        new Vivus('program-drawing', {
          type: 'sync',
          duration: 200,
          onReady: function () {
            $('.js-program-drawing svg').css('visibility', 'visible');
          }
        });
      }

    },

    _playVideo: function () {
      var parent = $(this).parent();

      if (parent.children('.video').get(0).paused) {
        parent.children('.video').get(0).play();
        parent.children('.playpause').fadeOut();
      } else {
        parent.children('.video').get(0).pause();
        parent.children('.playpause').fadeIn();
      }
    },

    _hideOverlay: function () {
      $(this).children('.js-video-overlay').fadeOut();
    },

    _detectActiveNav: function () {
      var url = window.location.pathname.split('/');
      var path = url[1];

      if (path) {
        $('.nav--main li a, .nav--mobile li a').each(function () {
          var el = $(this);
          if (el.attr('href').indexOf(path) > -1) {
            el.parent().addClass('current-menu-item');
          }
        })
      }
    },

  }; // EnergyEx

  EnergyEx.init();

})(jQuery, window, document);